import styles from "../../../styles/partials/newsletter.scss";
import StandardInput from "./StandardInput";
import FormContainer from "./FormContainer";
import SubmitButton from "./SubmitButton";
import * as formUtils from "./formUtils";
import { _classes } from "../../utilities/helpers";

const cl = _classes(styles);

Newsletter.propTypes = {
  page: PropTypes.object,
};

function Newsletter({ page }) {

  const renderForm = () => {
    return (
      <div
        className={cl([
          "newsletter__formContainer",
          `${
            !window.location.pathname.includes("restaurant") &&
            "newsletter__go_formContainer"
          }`,
        ])}
      >
        <div className={styles.newsletter__textContainer}>
          <div
            className={styles.newsletter__blurb}
            dangerouslySetInnerHTML={{ __html: page.blurb1 }}
          />
        </div>
        <div className={styles.newsletter__form}>
          <FormContainer
            formName="newsletter"
            formAriaLabel="newsletter form"
            postURL="/api/newsletter"
            formClassName={cl(["newsletter__formElement"])}
            onSubmitData={{
              googleAnalytics: {
                gaLabel: "Newsletter",
              },
            }}
            render={(formState, formFunctions) => {
              return (
                <div className={cl(["newsletter__inputContainer"])}>
                  <StandardInput
                    name="email"
                    formName="newsletter"
                    placeholder="YOUR EMAIL"
                    inputClassName={cl([
                      "newsletter__input",
                      `${
                        !window.location.pathname.includes("restaurant") &&
                        "newsletter__go_input"
                      }`,
                    ])}
                    containerClassName={cl(["newsletter__field"])}
                    errorClassName={styles.newsletter__input_error}
                    formState={formState}
                    formFunctions={formFunctions}
                    validator={formUtils.validateEmailFormat}
                    errorMessageClassName={styles.newsletter__errorMessage}
                    errorMessage="Please enter a valid email address"
                  />
                  <SubmitButton
                    formState={formState}
                    formFunctions={formFunctions}
                    containerClass={cl([
                      "newsletter__submit",
                      `${
                        !window.location.pathname.includes("restaurant") &&
                        "newsletter__go_submit"
                      }`,
                    ])}
                    className={cl(["newsletter__submitBtn"])}
                    buttonText="SUBMIT"
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  };

  return <div className={styles.newsletter}>{renderForm()}</div>;
}

export default Newsletter;

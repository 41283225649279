class SubmitButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    buttonText: PropTypes.string,
    formState: PropTypes.object.isRequired,
    containerClass: PropTypes.string,
    buttonIcon: PropTypes.func,
  };
  static defaultProps = {
    buttonIcon: () => {},
  };

  render() {
    const {
      formState,
      containerClass,
      buttonIcon,
      buttonText,
      className,
      style,
    } = this.props;
    return (
      !formState.posting && (
        <div className={containerClass}>
          <button
            type="submit"
            className={`${className} form-submit`}
            style={style}
          >
            {buttonText && <span>{buttonText}</span>}
            {buttonIcon && buttonIcon()}
          </button>
          <input
            type="submit"
            style={{ visibility: "hidden", display: "none", height: 0 }}
            disabled
          />
        </div>
      )
    );
  }
}

export default SubmitButton;

class LabelContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    legend: PropTypes.bool,
  };

  render() {
    return !this.props.legend ? (
      <label
        className={`${this.props.className} form-label`}
        style={this.props.style}
        htmlFor={this.props.name}
      >
        {this.props.children}
      </label>
    ) : (
      <legend className={this.props.className} style={this.props.style}>
        {this.props.children}
      </legend>
    );
  }
}

export default LabelContainer;

import React from "react";
import { motion } from "../utilities/FramerMotion";
import Link from "../widgets/Link";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/mobile-nav.scss";
import useAppState from "../providers/AppProvider";
import AnimateHeight from "react-animate-height";

const cl = _classes(styles);

const variantsnav = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 0 },
};
const variantsul = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const variants_li = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 80,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

MobileNav.propTypes = {};

export default function MobileNav() {
  const {
    menu,
    setScrollLocation,
    privateEvent,
    childrenById,
    opennav,
    setOpenNav,
    site,
    orderOptions,
    setOrderOptions,
    setSite,
    landingpages,
    byId,
  } = useAppState();

  const items = childrenById(site.id).filter((item) => item.showinnav === true);
  const orderOnlinePage = byId(9);

  return (
    <motion.div
      animate={opennav ? "open" : "closed"}
      variants={variantsnav}
      className={cl("_")}
    >
      <div className={cl(["modal_wrapper", "nav_wrapper"])}>
        <button
          onClick={() => setOpenNav(false)}
          className={cl(["modal_close", "left"])}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={cl("svg")}
          >
            <path d="m.35.35 21 21M.35 21.35l21-21" />
          </svg>
        </button>
        <div className={cl("modal_container")}>
          <motion.ul className={cl("items")} variants={variantsul}>
            {items &&
              items.map((item) => {
                const _template = item.template;
                const _pathname =
                  _template === "link_out" ? item.h6 : item.path;
                const active = window.location.pathname.includes(_pathname);
                const _item_path =
                  _template === "menus"
                    ? menu.path
                    : item.path.includes("/private-events")
                    ? privateEvent.path
                    : _pathname;
                return (
                  <li
                    key={item.id}
                    className={cl("item")}
                    variants={variants_li}
                  >
                    <Link
                      className={cl(["mobile_link", active && "nav_active"])}
                      path={_item_path}
                      onClick={() => {
                        setScrollLocation(_item_path), setOpenNav(false);
                      }}
                    >
                      {item.linktitle}
                    </Link>
                  </li>
                );
              })}
            <li className={cl(["item"])} variants={variants_li}>
              <div
                className={cl([
                  "link",
                  "no_after",
                  orderOptions && "open",
                  "order",
                ])}
                onClick={() => {
                  setOrderOptions(!orderOptions);
                }}
              >
                ORDER ONLINE
              </div>
              <ul className={cl(["order_items"])}>
                <AnimateHeight height={orderOptions ? "auto" : 0}>
                  <li className={cl(["order_item"])}>
                    <Link
                      className={cl(["sub_order", "doordash"])}
                      path={orderOnlinePage.h6}
                    >
                      Door Dash
                    </Link>
                  </li>
                  <li className={cl([""])}>
                    <Link
                      className={cl(["sub_order", "ubereats"])}
                      path={orderOnlinePage.h2}
                    >
                      Uber Eats
                    </Link>
                  </li>
                </AnimateHeight>
              </ul>
            </li>
            {orderOnlinePage.feature_link?.trim() && (
              <li className={cl("item")}>
                <Link
                  className={cl(["mobile_link"])}
                  path={orderOnlinePage.feature_link}
                >
                  Gift Cards
                </Link>
              </li>
            )}
            <li variants={variants_li}>
              <ul className={cl(["items", "bottom"])}>
                <li className={cl("item")}>
                  <Link
                    className={cl("mobile_link")}
                    path={"/restaurant"}
                    onClick={() => {
                      setSite(landingpages[0]), setOpenNav(false);
                    }}
                  >
                    {"RESTAURANT"}
                  </Link>
                </li>
              </ul>
            </li>
          </motion.ul>
        </div>
      </div>
    </motion.div>
  );
}

import { useAppState } from "../providers";
import Newsletter from "../widgets/form-components/Newsletter";
import Logo from "../components/Logo";
import { Link } from "../widgets";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/footer.scss";
import { Image } from "../widgets";
import zenscroll from "zenscroll";
import Popup from "../widgets/Popup";

const cl = _classes(styles);

Footer.propTypes = {
  page: PropTypes.object,
};

function Footer() {
  const {
    site,
    footers,
    setSite,
    landingpages,
    openPrivacyPolicy,
    setPrivacyPolicy,
  } = useAppState();

  if (!site) return false;
  const activeFooter = footers[0];
  const scrollToElement = (el) => {
    setTimeout(() => el && zenscroll.center(el, 500), 500);
  };

  const onCreditHover = () => {
    document.getElementById("credits").innerHTML = "ONCE UPON A TIME";
  };
  const onCreditLeave = () => {
    document.getElementById("credits").innerHTML = "CREDITS";
  };
  return (
    <footer>
      <Newsletter page={activeFooter} />
      <div className={cl("container")}>
        <div className={cl("top")}>
          <Logo
            className={cl("logo")}
            newSrc={activeFooter.image1}
            newAlt={activeFooter.image1_alt_text}
          />
        </div>
        <ul className={cl(["items"])}>
          <div>ADDRESS</div>
          <li
            className={cl(["address", "item"])}
            dangerouslySetInnerHTML={{ __html: activeFooter.address1 }}
          />
          <li className={cl(["phone", "item"])}>
            <Link className={cl(["link"])} path={`tel:212.935.3434`}>
              {"TEL " + activeFooter.phone}
            </Link>
          </li>
          <li className={cl(["social_media", "item"])}>
            <Link className={cl(["link"])} path={activeFooter.social_icon1}>
              <Image
                className={cl(["social_media_icon"])}
                src={activeFooter.image2}
                alt={activeFooter.image2_alt_text}
              />
            </Link>
            <Link className={cl(["link"])} path={activeFooter.social_icon2}>
              <Image
                className={cl(["social_media_icon"])}
                src={activeFooter.image3}
                alt={activeFooter.image3_alt_text}
              />
            </Link>
            <Link className={cl(["link"])} path={activeFooter.social_icon3}>
              <Image
                className={cl(["social_media_icon"])}
                src={activeFooter.image4}
                alt={activeFooter.image4_alt_text}
              />
            </Link>
          </li>
          {activeFooter.fieldgroup1 &&
            activeFooter.fieldgroup1.length > 0 &&
            activeFooter.fieldgroup1.map((item) => {
              return (
                <li key={item.id} className={cl(["item", "pages"])}>
                  <Link
                    className={cl(["link"])}
                    path={item.h2}
                    onClick={() => {
                      if (item.h1 === "CONTACT" || item.h1 === "CAREERS")
                        scrollToElement(document.getElementById("contact"));
                      setSite(landingpages[0]);
                      if (item.h1 === "PRIVACY POLICY") setPrivacyPolicy(true);
                    }}
                  >
                    {item.h1}
                  </Link>
                </li>
              );
            })}
          <li className={cl(["item", "pages", "credits"])}>
            <Link
              className={cl(["link"])}
              path={"https://www.onceuponatime.agency/hospitality"}
            >
              <div
                id="credits"
                className={cl(["credit_text"])}
                onMouseOver={onCreditHover}
                onMouseLeave={onCreditLeave}
              >
                CREDITS
              </div>
            </Link>
          </li>
        </ul>
        {openPrivacyPolicy && <Popup />}
      </div>
    </footer>
  );
}

export default Footer;

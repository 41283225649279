var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (development, hash, htmlWebpackPlugin) {pug_html = pug_html + "\u003C!DOCTYPE html\u003E\u003Chtml lang=\"en\"\u003E\u003Chead\u003E\u003Cmeta charset=\"utf-8\"\u003E\u003Cmeta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\"\u003E\u003Cmeta name=\"viewport\" content=\"width=device-width, initial-scale=1, user-scalable=no\"\u003E";
if (!(development)) {
pug_html = pug_html + "\u003Cscript\u003E(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\nm=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n})(window,document,'script','\u002F\u002Fwww.google-analytics.com\u002Fanalytics.js','ga');\nga('create', 'UA-34360576-1', 'auto');\nga('create', 'UA-38333321-35', 'auto', {'name': 'clientTracker'});\u003C\u002Fscript\u003E\u003Cscript type=\"application\u002Fld+json\"\u003E{\n\"@context\": \"https:\u002F\u002Fschema.org\",\n\"@type\": \"Restaurant\",\n\"name\": \"Fresco By Scotto\",\n\"image\": \"https:\u002F\u002Fs3.amazonaws.com\u002Ffrescobyscottosite\u002Ffrescobyscottometashareimage-1643060131703.jpg\",\n\"@id\": \"\",\n\"url\": \"http:\u002F\u002Fwww.frescobyscotto.com\",\n\"telephone\": \"2129353434\",\n\"priceRange\": \"$$$\",\n\"menu\": \"http:\u002F\u002Ffrescobyscotto.com\u002Frestaurant\u002Fmenus\u002Flunch\",\n\"servesCuisine\": \"Italian\",\n\"acceptsReservations\": \"true\",\n\"address\": {\n\"@type\": \"PostalAddress\",\n\"streetAddress\": \"34 East 52nd Street\",\n\"addressLocality\": \"New York\",\n\"addressRegion\": \"NY\",\n\"postalCode\": \"10022\",\n\"addressCountry\": \"US\"\n},\n\"geo\": {\n\"@type\": \"GeoCoordinates\",\n\"latitude\": 40.7588164,\n\"longitude\": -73.9745136\n},\n\"openingHoursSpecification\": {\n\"@type\": \"OpeningHoursSpecification\",\n\"dayOfWeek\": [\n\"Tuesday\",\n\"Wednesday\",\n\"Thursday\",\n\"Friday\",\n\"Saturday\"\n],\n\"opens\": \"12:00\",\n\"closes\": \"22:00\"\n},\n}\u003C\u002Fscript\u003E";
}
pug_html = pug_html + "\u003C!--meta--\u003E\u003C!--meta--\u003E";
if (!(development)) {
// iterate htmlWebpackPlugin.files.css
;(function(){
  var $$obj = htmlWebpackPlugin.files.css;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var css = $$obj[pug_index0];
pug_html = pug_html + "\u003Clink" + (pug.attr("href", css, true, true)+" rel=\"stylesheet\" type=\"text\u002Fcss\"") + "\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var css = $$obj[pug_index0];
pug_html = pug_html + "\u003Clink" + (pug.attr("href", css, true, true)+" rel=\"stylesheet\" type=\"text\u002Fcss\"") + "\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fhead\u003E\u003Cbody\u003E\u003C!--seo--\u003E\u003C!--seo--\u003E\u003Cdiv class=\"site-loader\"\u003E\u003Cdiv class=\"site-loader__content\"\u003E\u003Cdiv class=\"site-loader__spinner\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"wrapper\"\u003E\u003C\u002Fdiv\u003E";
if (development) {
pug_html = pug_html + "\u003Cscript" + (pug.attr("src", '/main' + hash + '.js', true, true)+" type=\"text\u002Fjavascript\" async") + "\u003E\u003C\u002Fscript\u003E";
}
else {
// iterate htmlWebpackPlugin.files.js
;(function(){
  var $$obj = htmlWebpackPlugin.files.js;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var js = $$obj[pug_index1];
pug_html = pug_html + "\u003Cscript" + (" type=\"text\u002Fjavascript\""+pug.attr("src", js, true, true)+" async") + "\u003E\u003C\u002Fscript\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var js = $$obj[pug_index1];
pug_html = pug_html + "\u003Cscript" + (" type=\"text\u002Fjavascript\""+pug.attr("src", js, true, true)+" async") + "\u003E\u003C\u002Fscript\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fbody\u003E\u003C\u002Fhtml\u003E";}.call(this,"development" in locals_for_with?locals_for_with.development:typeof development!=="undefined"?development:undefined,"hash" in locals_for_with?locals_for_with.hash:typeof hash!=="undefined"?hash:undefined,"htmlWebpackPlugin" in locals_for_with?locals_for_with.htmlWebpackPlugin:typeof htmlWebpackPlugin!=="undefined"?htmlWebpackPlugin:undefined));;return pug_html;};
module.exports = template;
import React from "react";
import { motion } from "../utilities/FramerMotion";
import Link from "../widgets/Link";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/widgets/_popup.scss";
import useAppState from "../providers/AppProvider";
import AnimateHeight from "react-animate-height";

const cl = _classes(styles);

const variantsnav = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 0 },
};
const variantsul = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const variants_li = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 80,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

Popup.propTypes = {};

export default function Popup() {
  const {
    setPrivacyPolicy,
    openPrivacyPolicy,
    byId,
  } = useAppState();
  
  const privacyPolicyPage = byId(10);

  return (
    <motion.div
      animate={openPrivacyPolicy ? "open" : "closed"}
      variants={variantsnav}
      className={cl("_")}
    >
      <div className={cl(["modal_wrapper", "nav_wrapper"])}>
        <button
          onClick={() => setPrivacyPolicy(false)}
          className={cl(["modal_close", "left"])}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={cl("svg")}
          >
            <path d="m.35.35 21 21M.35 21.35l21-21" />
          </svg>
        </button>
        <div className={cl("modal_container")}>
          <motion.ul
            className={cl("items")}
            variants={variantsul}
          >
            <li variants={variants_li}>
              <div
                className={cl([""])}
                dangerouslySetInnerHTML={{ __html: privacyPolicyPage.blurb2 }}
              />
            </li>
          </motion.ul>
        </div>
      </div>
    </motion.div>
  );
}

import React from "react";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/components/logo.scss";
import { Link } from "../widgets";
import restlogo from "../../images/logos/logo-restaurant.svg";
import { useAppState } from "../providers";
const cl = _classes(styles);
Logo.propTypes = {
  className: PropTypes.string,
  newSrc: PropTypes.string,
  newAlt: PropTypes.string,
};
Logo.defaultProps = {
  className: "",
};
export default function Logo({ className, newSrc, newAlt }) {
  const { site } = useAppState();
  const logo =
    site && site.image6 && site.image6 !== "" ? site.image6 : restlogo;

  return site ? (
    <div className={cl(["_", className])}>
      <Link path={site.path}>
        <img
          src={newSrc || logo}
          alt={newAlt || site.image6 ? site.image6_alt_text : site.h1}
          draggable={false}
        />
      </Link>
    </div>
  ) : (
    false
  );
}

import { createContext, useContext, useState } from "react";
import moment from "moment";
import { usePrevious } from "../utilities/Hooks";
const AppContext = createContext({});

AppProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export function AppProvider({ children, content, history, location }) {
  const [accessible, setAccessible] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [orderOptions, setOrderOptions] = useState(false);
  const [opennav, setOpenNav] = useState(false);
  const [openPrivacyPolicy, setPrivacyPolicy] = useState(false);
  const usesParent = {
    about: "landing",
    menus: "landing",
    menu: "landing",
    private_events: "landing",
    press: "landing",
    gallery: "landing",
    contact: "landing",
    about_detail: "landing",
    our_family: "landing",
    catering: "landing",
    private_event: "landing",
    menu_section: "landing",
  };
  const _sortEvents = (a, b) =>
    moment(a.start_date).toDate() - moment(b.start_date).toDate();

  const byId = (id) => content[id] || {};

  const byPath = (path) => {
    path = path.toLowerCase();
    path = path.slice(-1) === "/" && path.length > 1 ? path.slice(0, -1) : path;

    const id = Object.keys(content).find((key) => content[key].path === path);
    return content[id] || {};
  };

  const byTemplate = (template) =>
    Object.keys(content)
      .filter((key) => content[key].template === template)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);
  const landingpages = byTemplate("landing");
  const menupages = byTemplate("menu");
  const [site, setSite] = useState(landingpages[0]);
  const [menu, setMenu] = useState(menupages[0]);
  const footers = byTemplate("footer");
  const [sitefooter, setSiteFooter] = useState(footers[0]);
  const [scrollLocation, setScrollLocation] = useState(
    window.location.pathname
  );
  const [prevState, setpreviousLocation] = useState(window.location.pathname);
  const previousLocation = usePrevious(prevState);
  const [aboutparent] = byTemplate("about");
  const privateEventPages = byTemplate("private_event");
  const [privateEvent, setPrivateEvent] = useState(privateEventPages[0]);

  const childrenById = (id) =>
    Object.keys(content)
      .filter((key) => content[key].parentid === id)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);

  const childrenByPath = (path) => childrenById(byPath(path).id);

  const parentById = (id) => byId(byId(id).parentid);

  const parentByPath = (path) => byId(byPath(path).parentid);
  const [aboutpage, setAboutPage] = site
    ? useState(
        childrenById(aboutparent.id).find((p) => p.path.includes(site.path)) ||
          []
      )
    : useState(null);

  const allEvents = () => {
    const events = byTemplate("calendar_detail");

    return events
      .reduce((sum, event) => {
        const eventType = JSON.parse(event.calendartype).type;

        if (eventType === "recurring" && event.parentid === event.calendarid) {
          const children = childrenById(event.id).sort(_sortEvents);

          const child =
            children.find(
              (e) => moment(e.start_date).toDate() >= moment().toDate()
            ) || event;

          if (moment(event.start_date).toDate() >= moment().toDate()) {
            //if original event is still in future, push OG event
            sum.push(event);
          } else {
            //otherwise push most next upcoming child
            sum.push({
              ...event,
              id: child.id,
              parentid: child.parentid,
              start_date: child.start_date,
              end_date: child.end_date,
            });
          }
        }

        if (eventType !== "recurring") {
          sum.push(event);
        }

        return sum;
      }, [])
      .sort(_sortEvents);
  };

  const activeEvents = () => {
    return allEvents()
      .filter((event) => {
        const today = new Date();
        const calendarType = JSON.parse(event.calendartype);

        return (
          moment(event.end_date).toDate().getTime() >= today.getTime() ||
          (calendarType.type === "consecutive" &&
            moment(event.end_date).toDate().getTime() >= today.getTime())
        );
      })
      .sort(_sortEvents);
  };

  const toggleAccessible = (e) => {
    e.preventDefault();
    setAccessible(!accessible);
    document.querySelector("html").classList.toggle("accessible");
  };

  return (
    <AppContext.Provider
      value={{
        content,
        history,
        location,
        byId,
        byPath,
        byTemplate,
        childrenById,
        childrenByPath,
        parentById,
        parentByPath,
        allEvents,
        activeEvents,
        accessible,
        toggleAccessible,
        animating,
        setAnimating,
        site,
        setSite,
        landingpages,
        sitefooter,
        setSiteFooter,
        footers,
        usesParent,
        menu,
        setMenu,
        scrollLocation,
        setScrollLocation,
        setpreviousLocation,
        previousLocation,
        aboutpage,
        setAboutPage,
        privateEvent,
        privateEventPages,
        setPrivateEvent,
        orderOptions,
        setOrderOptions,
        opennav,
        setOpenNav,
        openPrivacyPolicy,
        setPrivacyPolicy
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default function useAppState() {
  return useContext(AppContext);
}

import * as FramerMotion from "framer-motion";
export * from "framer-motion";

const { createDomMotionComponent } = FramerMotion;

export const motion = {
  div: createDomMotionComponent("div"),
  ul: createDomMotionComponent("ul"),
  li: createDomMotionComponent("li"),
  svg: createDomMotionComponent("svg"),
  header: createDomMotionComponent("header"),
  nav: createDomMotionComponent("nav"),
  h1: createDomMotionComponent("h1"),
  h2: createDomMotionComponent("h2"),
  h3: createDomMotionComponent("h3"),
  ol: createDomMotionComponent("ol"),
};

export default class ThankYou extends React.Component {
  static propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  static defaultProps = {
    text: "Thank you.",
    className: "",
    style: {
      display: "inline-block",
    },
  };

  render() {
    const { text, className } = this.props;
    return (
      <div
        className={`global-blurb ${className}`}
        style={{ textAlign: "center", marginTop: "50px" }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    );
  }
}

import Link from "../widgets/Link";
import { useAppState, useModalContext, useViewportContext } from "../providers";
import CTA from "../components/CTA";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/nav.scss";

const cl = _classes(styles);

Nav.propTypes = {
  orderOptions: PropTypes.bool,
  setOrderOptions: PropTypes.func,
};

function Nav({ orderOptions, setOrderOptions }) {
  const { childrenById, site, menu, setScrollLocation, privateEvent, byId } =
    useAppState();
  if (!site) return false;
  const items =
    site && childrenById(site.id).filter((item) => item.showinnav === true);
  const { viewport } = useViewportContext();
  const large = viewport.is("large");
  const orderOnlineRestaurantPage = byId(9);
  if (large) return <div></div>;
  else
    return (
      <nav
        className={cl([
          "_",
          `${!window.location.pathname.includes("restaurant") && "go"}`,
        ])}
      >
        <ul className={cl(["items"])}>
          {items &&
            items.map((item) => {
              const _template = item.template;
              const _pathname = _template === "link_out" ? item.h6 : item.path;
              const active = window.location.pathname.includes(_pathname);
              const _item_path =
                _template === "menus"
                  ? menu.path
                  : item.path.includes("/private-events")
                  ? privateEvent.path
                  : _pathname;
              return (
                <li key={item.id} className={cl("item")}>
                  <Link
                    className={cl(["link", active && "nav_active"])}
                    path={_item_path}
                    onClick={() => setScrollLocation(_item_path)}
                  >
                    {item.linktitle}
                  </Link>
                </li>
              );
            })}

          <li
            className={cl(["item", orderOptions && "open", "order"])}
            onClick={() => setOrderOptions(!orderOptions)}
          >
            <div className={cl(["link", "no_after"])}>ORDER ONLINE</div>
            <Link
              className={cl(["sub_order", "doordash", orderOptions && "open"])}
              path={orderOnlineRestaurantPage.h6}
            >
              Door Dash
            </Link>
            <Link
              className={cl(["sub_order", "ubereats", orderOptions && "open"])}
              path={orderOnlineRestaurantPage.h2}
            >
              Uber Eats
            </Link>
          </li>

          <li className={cl(["item", "book_item"])}>
            {orderOnlineRestaurantPage.feature_link?.trim() && (
              <CTA
                text={"gift cards"}
                path={orderOnlineRestaurantPage.feature_link}
                className={cl(["book_cta", "gift_cards"])}
              />
            )}
            <CTA
              text={"reservations"}
              path={orderOnlineRestaurantPage.h3}
              className={cl("book_cta")}
            />
          </li>
        </ul>
      </nav>
    );
}

export default Nav;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo______2eEaN{max-width:100px}", "",{"version":3,"sources":["webpack://./src/styles/components/logo.scss"],"names":[],"mappings":"AAGA,iBACE,eAAA","sourcesContent":["@import \"../global/parts\";\n@include _minWidthMobile{\n}\n._{\n  max-width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "logo______2eEaN"
};
export default ___CSS_LOADER_EXPORT___;

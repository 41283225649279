import { useEffect, useState } from "react";
import { LazyBoundary } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import useAppState from "../providers/AppProvider";
import ErrorBoundary from "../utilities/ErrorBoundary";
import { AnimatePresence, motion } from "../utilities/FramerMotion";
import viewsPaths from "../utilities/viewPaths";

Routes.propTypes = {};

export default function Routes() {
  const { content, location, site, byPath, usesParent } = useAppState();
  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    const baseRoutes = createBaseRoutes();
    setRoutes(baseRoutes);
  }, []);
  const setPage = (page) => (usesParent[page.template] ? site : page);
  const createBaseRoutes = () => {
    const donotroute = ["footer", "header"];
    return Object.keys(content)
      .map((key) => {
        const page = content[key];
        const formattedPage = setPage(page);
        const template = formattedPage.template.toLowerCase();
        const Component = viewsPaths[`${template}`] || viewsPaths["default"];
        if (Object.keys(page).length === 0) {
          console.log("%c MATCH", "color:red");
          return (
            <Route
              path="/"
              key="redirectAllElse"
              render={() => <Redirect to="/" />}
            />
          );
        }
        return (
          !donotroute.includes(page.template) && (
            <Route
              exact
              key={formattedPage.id}
              path={page.path}
              render={(props) => (
                <LazyBoundary fallback={<div />}>
                  <ErrorBoundary>
                    <Component page={formattedPage} {...props} />
                  </ErrorBoundary>
                </LazyBoundary>
              )}
            />
          )
        );
      })
      .concat(
        <Route
          path="/"
          key="redirectAllElse"
          render={() => <Redirect to="/" />}
        />
      );
  };
  const formattedPage = setPage(byPath(location.pathname));
  return (
    <main>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.75, delay: 0 }}
          className="page"
          key={formattedPage.id}
        >
          <Switch location={location}>{routes}</Switch>
        </motion.div>
      </AnimatePresence>
    </main>
  );
}

import React from "react";
import { Link } from "../widgets";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/components/cta.scss";
import { useAppState } from "../providers";
const cl = _classes(styles);
CTA.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.func,
};
export default function CTA({ text, path, onClick, className }) {
  return (
    <div className={cl(["_"])}>
      <Link className={cl(["cta", className])} path={path} onClick={onClick}>
        {text}
      </Link>
    </div>
  );
}

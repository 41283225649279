import Logo from "../components/Logo";
import { useAppState, useViewportContext } from "../providers";
import { Link } from "../widgets";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/header.scss";
import Nav from "./Nav";
import CTA from "../components/CTA";
import MobileNav from "./MobileNav";

const cl = _classes(styles);

Header.propTypes = {};
function Header() {
  const { site, orderOptions, setOrderOptions, opennav, setOpenNav } =
    useAppState();

  const { viewport } = useViewportContext();
  const large = viewport.is("large");

  const renderHamburger = () => {
    return (
      <div className={cl("hamburger")} onClick={() => setOpenNav(!opennav)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  };

  return (
    <header className={cl(["_"])}>
      <div className={cl("container")}>
        <div className={cl("top")}>
          {large && (
            <div className={cl("site_nav_mobile")}>{renderHamburger()}</div>
          )}
          <div className={cl("logo")}>
            <Logo />
          </div>
          {large ? (
            <CTA
              text={"reserve"}
              path={"https://resy.com/cities/ny/fresco-by-scotto"}
              className={cl("book_cta")}
            />
          ) : (
            <div className={cl("phone")}>
              <Link className={cl(["link"])} path={`tel:212.935.3434`}>
                {"212 935 3434"}
              </Link>
            </div>
          )}
        </div>
        {large && opennav ? (
          <MobileNav />
        ) : (
          <Nav orderOptions={orderOptions} setOrderOptions={setOrderOptions} />
        )}
      </div>
    </header>
  );
}

export default Header;

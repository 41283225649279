import browser from "bowser";
import InvalidBrowser from "./utilities/InvalidBrowser/InvalidBrowser.js";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import APP_CONFIG from "./config/appConfig";
import Routes from "./containers/Routes.js";
import { useEffect } from "react";
import useAppState from "./providers/AppProvider.js";
import zenscroll from "zenscroll";

App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default function App({ location }) {
  const {
    byPath,
    landingpages,
    setSite,
    setSiteFooter,
    site,
    footers,
    usesParent,
    setpreviousLocation,
    setScrollLocation,
    setAboutPage,
    setMenu,
    setPrivateEvent,
    childrenById,
  } = useAppState();
  const currentpath = location.pathname;
  let _get_site = currentpath.split("/");
  useEffect(() => {
    pageChangeRoutine();
    setTimeout(hideLoader, APP_CONFIG.loadingDuration);
    setpreviousLocation(currentpath);
    setScrollLocation(location.pathname);
    if (window.location.pathname === "/") {
      setSite(landingpages[0]);
    } else {
      let _site_path = _get_site[1];
      const currentsite = landingpages.find(
        (site) => `/${_site_path}` === site.path
      );
      const footer = footers.find((p) => p.path.includes(`/${_site_path}`));
      setSite(currentsite);
      setSiteFooter(footer);
    }

    if (currentpath.includes("/menus/")) {
      const _menu = byPath(location.pathname);
      setMenu(_menu);
    }
    if (currentpath.includes("/private-events")) {
      const _privateEvent = byPath(location.pathname);
      setPrivateEvent(_privateEvent);
    }
    if (currentpath.includes("/about/")) {
      const _about = byPath(location.pathname);
      console.log("_ABOUT ", _about);
      setAboutPage(_about);
    }
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
    setpreviousLocation(location.pathname);
    // setScrollLocation(location.pathname);
  }, [location.pathname, location.search]);
  const sitepathtest = site && site.path ? site.path : null;
  useEffect(() => {
    const currentpath = location.pathname;

    let _get_site = currentpath.split("/");
    let _site_path = _get_site[1];
    const footer = footers.find((p) => p.path.includes(`/${_site_path}`));
    setSiteFooter(footer);
  }, [sitepathtest]);
  const hideLoader = () =>
    (document.querySelector(".site-loader").className +=
      " site-loader--loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;

    if (!usesParent[page.template]) {
      zenscroll.toY(0);
    }
  };

  const trackPageView = (page) => {
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
  };

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }
  const { pathname } = window.location;
  const home = pathname === "/";
  return (
    <div className={`app`}>
      {!home && <Header />}
      <Routes />
      {!home && <Footer />}
    </div>
  );
}
